import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['input',
                    'resetControllerElement',
                    'staticDataWrapper', 
                    'overrideFormWrapper',
                    'overrideButton', 
                    'useDefaultButton']

  connect() {
    const inputBlocks = this.overrideFormWrapperTarget.querySelectorAll('.input-block');
    var isOverriden = false;
    for (var i = 0; i < inputBlocks.length; ++i) {
      if(!inputBlocks[i].querySelector('.reset-value').classList.contains('hidden')){
        isOverriden = true;
        break;
      }
    }

    isOverriden? this.showForm() : this.hideForm();
  }

  changeLanguage(){
    const selectedLanguage = this.inputTarget.value;
    const dataBlocks = [...document.querySelectorAll('.data-block')];
    dataBlocks.forEach((block) => {
      block.classList.add('hidden');
    });
    if(selectedLanguage && selectedLanguage != ''){
      document.querySelector('.data-block[data-locale='+ selectedLanguage +']').classList.remove('hidden');
    }
    else{
      document.querySelector('.data-block').classList.remove('hidden');
    }
  }

  showForm(event) {
    if(event) event.preventDefault();
    const dataBlocks = [...document.querySelectorAll('.data-block')];
    dataBlocks.forEach((block) => {
      block.classList.add('hidden');
    });
    this.staticDataWrapperTarget.classList.add('hidden');
    this.overrideFormWrapperTarget.classList.remove('hidden');
  }

  hideForm(event) {
    if(event) event.preventDefault();
    this.resetControllerElementTargets.forEach(target => {
      if(!target.querySelector('.reset-value').classList.contains('hidden')) this.resetFormField(target);
    });
    this.overrideFormWrapperTarget.classList.add('hidden');
    this.staticDataWrapperTarget.classList.remove('hidden');
    this.changeLanguage();
  }

  resetFormField(target) {
    const resetInputController = this.application.getControllerForElementAndIdentifier(target, 'reset-input');
    resetInputController.resetInput();
  }
}

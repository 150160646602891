import { Controller } from "stimulus"
export default class extends Controller {

  removeScreen(){
    let itemToRemove = event.currentTarget.dataset.screenId;

    const screenRow = document.getElementById(`row-${itemToRemove}`);
    if (screenRow) {
      const confirmationMsg = event.currentTarget.dataset.confirmationMsg;
      let confirmDeletion = confirm(confirmationMsg);

      if (confirmDeletion) {
        let screenIdsElement = document.getElementById("screens_selector_screen_ids");
        let value = screenIdsElement.value;
        let array = value.split(" ");
        array = array.filter(item => item !== itemToRemove);
        let newValue = array.join(" ");
        screenIdsElement.value = newValue;

        screenRow.remove();
      }
    }

  }
}
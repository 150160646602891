import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["sidebarToggleButton"];
  
  toggleSidebar(){
    this.element.dispatchEvent(
      new CustomEvent("toggleSidebar", {
        bubbles: true
      })
    )
  }
  
}

import { Controller } from "stimulus"
import { FetchRequest } from "@rails/request.js"

export default class extends Controller {
  static targets = ["sidebarNavigation"]

  connect(){
    this.sidebarNavigationTarget.classList.contains("collapsed") ? document.querySelector('header').classList.add('sidebar-collapsed') : document.querySelector('header').classList.remove('sidebar-collapsed');
  }

  collapse() {
    this.updateCustomerSetting();

    this.sidebarNavigationTarget.classList.toggle("collapsed");

    this.sidebarNavigationTarget.classList.contains("collapsed") ? document.querySelector('header').classList.add('sidebar-collapsed') : document.querySelector('header').classList.remove('sidebar-collapsed');
  }

  async updateCustomerSetting() {
    const collapsed = this.sidebarNavigationTarget.classList.contains("collapsed");
    const sidebarFormat = collapsed ? "expanded" : "collapsed";

    const request = new FetchRequest("patch", "/customer", {
      body: {
        customer: {
          sidebar_format: sidebarFormat
        }
      },
      responseKind: "json"
    })

    await request.perform();
  }
}

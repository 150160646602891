import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["openMenuButton", "closeMenuButton", "sidebarMenu", "compactMenuButton", "siteHeader", "mainPageContent"]

  connect(){
    if(!this.hasSidebarMenuTarget) this.siteHeaderTarget.classList.add('no-sidebar')
    this.siteHeaderTarget.style.setProperty('--header-before-height', this.siteHeaderTarget.offsetHeight + 'px');
    this.hasTriggered = false;
    this.handleScroll();
    this.handleScroll = this.handleScroll.bind(this)
    window.addEventListener('scroll', this.handleScroll, { passive: true })
  }

  handleScroll() {
    const scrollY = window.scrollY
    const scrollX = window.scrollX
    // const scrollHeight = document.documentElement.scrollHeight; // Total scrollable height
    // const viewportHeight = document.documentElement.clientHeight;
    // const scrollPercentage = (scrollY / (scrollHeight - clientHeight)) * 100;
    
    if (this.hasTriggered == false && scrollY > 10) {
      this.siteHeaderTarget.classList.add('scroll-shadow');
      this.hasTriggered = true;
    }

    if (this.hasTriggered == true && scrollY < 10) {
      this.siteHeaderTarget.classList.remove('scroll-shadow');
      this.hasTriggered = false;
    }
  }
  
  toggleMenu() {
    document.body.classList.toggle('fixed');
    this.sidebarMenuTarget.classList.toggle('open');
    this.openMenuButtonTarget.classList.toggle('display-none');
    this.closeMenuButtonTarget.classList.toggle('display-none');
  }

  toggleCompactVersion() {
    this.sidebarMenuTarget.classList.toggle('compact-version');
  }
}

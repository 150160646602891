import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "inputField", "resetButton" ]

  connect() {
    if(this.hasInputFieldTarget){
      this.toggleResetButton();
    }
  }

  resetInput(){
    if(this.inputFieldTarget) {
      this.inputFieldTarget.value = this.inputFieldTarget.getAttribute('data-default');
      this.resetButtonTarget.classList.add('hidden');
    }
  };

  toggleResetButton(){
    if ((this.inputFieldTarget.hasAttribute('data-default') && this.inputFieldTarget.value != this.inputFieldTarget.getAttribute('data-default'))
      || (!this.inputFieldTarget.hasAttribute('data-default') && this.inputFieldTarget.value != '')){
      this.resetButtonTarget.classList.remove('hidden');
    } else {
      this.resetButtonTarget.classList.add('hidden');
    }
  }
}

import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ["btn", "tab"]

  static values = {
    turboFrameId: String,
    setCustomEvent: Boolean 
  }

  connect(){
    let selectedBtn = this.btnTargets.find(element => element.classList.contains('active'));
    
    if(selectedBtn){
      const tabNavigation = selectedBtn.closest('.tab-navigation-component')
      
      if (tabNavigation) {
        tabNavigation.classList.remove('tabs-css-status')//As there is a valid selectedBtn and the controller has connected correctly, we remove the class that had been setup just in case the JS controller did not connect/load. This class allows for correct active states if the controller is not present or fails to load, resorting to simple CSS styles and classes.
        
        const beforeParentElement = tabNavigation.querySelector('.tab-navigation-cta');
      
        beforeParentElement.style.setProperty('--tab-before-transition', 'none'); //We remove the transition to avoid the animation always moving from the very left to the selected element on first load

        this.animateTabChange(selectedBtn);
        setTimeout(() => { beforeParentElement.style.setProperty('--tab-before-transition', 'all 0.15s cubic-bezier(0.4, 0, 0.2, 1)')}, 150); //We add the transition back to animate any other possible tab changes
      }
    }
  }

  change(event) {
    let selectedBtn = event.currentTarget;
    let selectedTab = this.tabTargets.find(element => element.dataset.targetId === selectedBtn.dataset.targetId);

    this.tabTargets.map(tab => tab.classList.add('hidden'));
    this.btnTargets.map(btn => btn.classList.remove('active'));

    selectedTab.classList.remove('hidden');
    selectedBtn.classList.add('active');

    this.animateTabChange(selectedBtn);

    if (this.setCustomEventValue) {
      // Custom event to trigger different controller events
      const trigger = new CustomEvent("tabChanged");
      window.dispatchEvent(trigger);
    }
  }

  selectChange(event) {
    let selectedOption = event.currentTarget.value;
    let selectedTab = this.tabTargets.find(element => element.dataset.targetId === selectedOption);

    this.tabTargets.map(tab => tab.classList.add('hidden'));

    selectedTab.classList.remove('hidden');

    // Custom event to trigger different controller events
    const trigger = new CustomEvent('tabChanged');
    window.dispatchEvent(trigger);
  }

  selectFrameChange(event) {
    const frame = document.getElementById(this.turboFrameIdValue);
    frame.src=event.target.value;
    frame.reload();
  }

  goToUrl(event){
    event.target.disabled = true;
    window.location = event.target.value;
  }

  animateTabChange(selectedBtn){
    const tabNavitgation = selectedBtn.closest('.tab-navigation-component')

    if (tabNavitgation) {
      const beforeParentElement = tabNavitgation.querySelector('.tab-navigation-cta');
      const selectedRect = selectedBtn.getBoundingClientRect();
      const selectedRectWidth = selectedRect.width;
      const parentRect = selectedBtn.parentElement.getBoundingClientRect();
      const distanceFromLeft = selectedRect.left - parentRect.left - 5; //5px spacing removed to account for spacing between tab component elements

      beforeParentElement.style.setProperty('--tab-before-left', distanceFromLeft + 'px');
      beforeParentElement.style.setProperty('--tab-before-width', selectedRectWidth + 'px');
    }
  }
}

import { Controller } from "stimulus"
import { FetchRequest } from "@rails/request.js"

export default class extends Controller {
  static targets = ["inputGrid", "inputList"];

  connect(){
    this.shouldUpdate = this.data.get('shouldUpdate') || true;
  }

  activateGridView(event) {
    if(!event.target.classList.contains('active')) {
      this.inputListTarget.classList.remove('before:inset-0', 'hover:cursor-not-allowed', 'active');
      this.inputListTarget.classList.add('before:-start-16'); 
      this.inputGridTarget.classList.add('hover:cursor-not-allowed','active');
    }
    this.shouldUpdate ? this.updateCustomerSetting("grid") : false;
  }

  activateListView(event, shouldUpdate = true) {
    if(!event.target.classList.contains('active')) {
      this.inputGridTarget.classList.remove('hover:cursor-not-allowed', 'active');
      this.inputListTarget.classList.remove('before:-start-16');  
      this.inputListTarget.classList.add('before:inset-0', 'hover:cursor-not-allowed', 'active');
    }
    this.shouldUpdate ? this.updateCustomerSetting("list") : false;
  }

  toggleButtonStyle(button) {
    this.inputTargets.forEach(target => {
      target.classList.toggle('bg-blue-300')
      target.classList.toggle('text-white')
      target.classList.toggle('hover:bg-blue-300')
      target.classList.toggle('hover:text-white')
      target.classList.toggle('active')
    });
  }

  async updateCustomerSetting(viewFormat) {
    const request = new FetchRequest("patch", "/customer", {
      body: {
        customer: {
          view_format: viewFormat
        }
      },
      responseKind: "json"
    })
    const response = await request.perform()
  }
}

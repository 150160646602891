import { Controller } from "stimulus"
export default class extends Controller {

  static targets = [
    "form",
    "durationInput",
    "recurringInput",
    "oneOffFieldsContainer",
    "recurringFieldsContainer"
  ]

  connect() {
    this.formTarget.addEventListener("submit", (event) => this.validateForm(event));
    console.log('this.recurringInputTarget.value');
    console.log(this.recurringInputTarget.value);
    if (this.recurringInputTarget.value == '1' || this.recurringInputTarget.value == 1 || this.recurringInputTarget.value == true || this.recurringInputTarget.value == 'true'){
      this.recurringSelected();
    } else {
      this.oneOffSelected();
    }
  }

  selectRadio() {
    let assetId = event.currentTarget.dataset.assetId;
    var radio = document.getElementById('radio_asset_' + assetId)
    radio.checked = true;

    var allContainers = document.querySelectorAll('.asset-card');
    allContainers.forEach(div => div.classList.remove('outline'));

    var assetContainer = document.getElementById('asset_' + assetId);
    assetContainer.querySelector('.asset-card').classList.add('outline');

    this.setDurationFields(event.currentTarget.dataset.duration)
  }

  setDurationFields(duration){
    this.durationInputTargets.forEach(target => {
      target.value = duration;
    });
  }
  
  oneOffSelected(){
    console.log('one off Selected');
    this.recurringInputTarget.value = 0;
  }

  recurringSelected(){
    console.log('recurring Selected');
    this.recurringInputTarget.value = 1;
  }

  validateForm(event) {
    try {
      if (this.recurringInputTarget.value == 1){
        this.validatesRecurrentFields(event)
      } else {
        this.validatesOneOffFields(event)
      }
    } catch (error) {
      event.preventDefault();
      console.log(`An error occurred: ${error.message}`);
      console.log(error);
    }
  }

  validatesOneOffFields(event) {
    let valid = true;

    const date = this.getElementByName('scheduled_trigger[one_off][start_date]');
    const dateErrorMessage = date.dataset.errorMessage || "Override date is needed";
    if (date.value == '' || date.value == null) {
      this.showError(date, dateErrorMessage);
      valid = false;
    } else {
      this.clearError(date);
    }

    const duration = this.getElementByName('scheduled_trigger[one_off][duration]');
    const durationErrorMessage = duration.dataset.errorMessage || "Duration needs to be greater than 0";
    if (duration.value <= 0) {
      this.showError(duration, durationErrorMessage);
      valid = false;
    } else {
      this.clearError(duration);
    }

    const overrideTime = this.getElementByName('scheduled_trigger[one_off][start_time]');
    const overrideTimeErrorMessage = overrideTime.dataset.errorMessage || "Override time is needed";
    if (overrideTime.value == '' || overrideTime.value == null) {
      this.showError(overrideTime, overrideTimeErrorMessage);
      valid = false;
    } else {
      this.clearError(overrideTime);
    }

    const playable = document.querySelector('input[name="playable_type"]');
    const selectedPlayable = document.querySelector('input[name="playable_id"]:checked');
    const playableError = document.getElementById('playable-error-message').dataset.errorMessage;
    if(selectedPlayable != null){
      this.clearError(playable);
    } else {
      valid = false;
      this.showError(playable, playableError, 'mb-5');
    }

    const screens = document.getElementById('screens_selector_screen_ids');
    const screensError = document.getElementById('screens-error-message').dataset.errorMessage;
    if (screens.value == null || screens.value == "" || screens.value == []) {
      this.showError(screens, screensError, 'mb-5');
      valid = false;
    } else {
      this.clearError(screens);
    }

    if (!valid) {
      event.preventDefault();
    }
  }

  validatesRecurrentFields(event) {
    let valid = true;

    const startDate = this.getElementByName('scheduled_trigger[recurrent][start_date]');
    const startDateErrorMessage = startDate.dataset.errorMessage || "Start date is needed";
    if (startDate.value == '' || startDate.value == null) {
      this.showError(startDate, startDateErrorMessage);
      valid = false;
    } else {
      this.clearError(startDate);
    }

    const endDate = this.getElementByName('scheduled_trigger[recurrent][end_date]');
    const endDateErrorMessage = endDate.dataset.errorMessage || "End date is needed";
    if (endDate.value == '' || endDate.value == null) {
      this.showError(endDate, endDateErrorMessage);
      valid = false;
    } else {
      this.clearError(endDate);
    }

    const frequency = this.getElementByName('scheduled_trigger[recurrent][frequency_time]');
    const frequencyErrorMessage = frequency.dataset.errorMessage || "Frequency is required";
    if (frequency.value <= 0 || frequency.value == '') {
      this.showError(frequency, frequencyErrorMessage);
      valid = false;
    } else {
      this.clearError(frequency);
    }

    const duration = this.getElementByName('scheduled_trigger[recurrent][duration]');
    const durationErrorMessage = duration.dataset.errorMessage || "Duration needs to be greater than 0";
    if (duration.value <= 0) {
      this.showError(duration, durationErrorMessage);
      valid = false;
    } else {
      this.clearError(duration);
    }

    const overrideTime = this.getElementByName('scheduled_trigger[recurrent][start_time]');
    const overrideTimeErrorMessage = overrideTime.dataset.errorMessage || "Override time is needed";
    if (overrideTime.value == '' || overrideTime.value == null) {
      this.showError(overrideTime, overrideTimeErrorMessage);
      valid = false;
    } else {
      this.clearError(overrideTime);
    }

    const weekdaysContainer = document.getElementById('days-input-container');
    const weekdaysContainerErrorMessage = weekdaysContainer.dataset.errorMessage || "Select at least one day";
    const weekdays = document.getElementsByName('scheduled_trigger[recurrent][repeat_days][]');
    let checked = false;
    for (let day of weekdays) {
      if (day.checked) {
        checked = true;
        break;
      }
    }
    if (checked){
      this.clearError(weekdaysContainer);
    } else {
      this.showError(weekdaysContainer, weekdaysContainerErrorMessage);
      valid = false;
    }

    const playable = document.querySelector('input[name="playable_type"]');
    const selectedPlayable = document.querySelector('input[name="playable_id"]:checked');
    const playableError = document.getElementById('playable-error-message').dataset.errorMessage;
    if(selectedPlayable != null){
      this.clearError(playable);
    } else {
      valid = false;
      this.showError(playable, playableError, 'mb-5');
    }

    const screens = document.getElementById('screens_selector_screen_ids');
    const screensError = document.getElementById('screens-error-message').dataset.errorMessage;
    if (screens.value == null || screens.value == "" || screens.value == []) {
      this.showError(screens, screensError, 'mb-5');
      valid = false;
    } else {
      this.clearError(screens);
    }

    if (!valid) {
      event.preventDefault();
    }
  }

  getElementByName(name){
    const element = document.getElementsByName(name)[0];
    return element
  }

  showError(target, message, extraClass = '') {
    let error = target.nextElementSibling;
    if (!error || !error.classList.contains('error')) {
      error = document.createElement('div');
      error.classList.add('text-red-400', 'error');
      if (extraClass) {
        error.classList.add(extraClass);
      }
      target.after(error);
      target.classList.add('border-red-400');
      target.parentNode.classList.remove('mb-6');
    }
    error.textContent = message;
  }

  clearError(target) {
    let error = target.nextElementSibling;
    if (error && error.classList.contains('error')) {
      error.remove();
      target.classList.remove('border-red-400');
      target.parentNode.classList.add('mb-6');
    }
  }
}
import { Controller } from '@hotwired/stimulus';
import Choices from 'choices.js'

export default class extends Controller {
  static values = {
    removeItemButton: { type: Boolean, default: true },
    remoteSearch: Object,
    defaultItems: Array,
    defaultChoices: Array,
    maxItemCount: Number,
    delimiter: { type: String, default: ',' },
    duplicateItemsAllowed: { type: Boolean, default: true },
    editItems: { type: Boolean, default: false },
    validateItemFilterType: String,
    shouldSort: { type: Boolean, default: true },
    saveOnEnter: { type: Boolean, default: false }
  }

  initialize() {
    let options = {
      removeItemButton: this.removeItemButtonValue,
      searchResultLimit: 20,
      itemSelectText: '',
      delimiter: this.delimiterValue,
      duplicateItemsAllowed: this.duplicateItemsAllowedValue,
      editItems: this.editItemsValue,
      shouldSort: this.shouldSortValue,
      saveOnEnter: this.saveOnEnterValue
    }

    if (this.defaultItemsValue) {
      options['items'] = this.defaultItemsValue
    }
    if (this.maxItemCountValue) {
      options['maxItemCount'] = this.maxItemCountValue
    }
    if (this.defaultChoicesValue) {
      options['choices'] = this.defaultChoicesValue
    }

    if (this.hasValidateItemFilterTypeValue) {
      switch(this.validateItemFilterTypeValue) {
        // We can add more validation types if necessary
        case('email'):
          const emailPattern = /^((?!\.)[\w\-_.]*[^.])@[\w-]+(\.\w+(\.\w+)?[^.\W])$/;
          options['addItemFilter'] = value => emailPattern.test(value);
      }
    }

    if (document.getElementById('translation_messages')) {
      const noChoicesText = document.getElementById('translation_messages').dataset.noChoicesText 
      const noResultsText = document.getElementById('translation_messages').dataset.noResultsText
    
      options['noChoicesText'] = noChoicesText
      options['noResultsText'] = noResultsText
    }

    const choices = new Choices(this.element, options);

    if (Object.keys(this.defaultItemsValue).length > 0) {
      choices.setValue(this.defaultItemsValue);
    }

    if (Object.keys(this.remoteSearchValue).length > 0) {
      this.element.addEventListener('search', async (e) => {
        if (e.detail.value.length < 3) {
          choices.setChoices([], 'value', 'label', true);
          return;
        }

        let data = await this.performRemoteSearch(e.detail.value).then(res => {
          return res;
        });
    
        choices.setChoices(data, 'value', 'label', true);
      });
    }
    
    if(this.saveOnEnterValue == true){
      this.element.addEventListener(
        'addItem',
        function() {
          const newEvent = new Event('change', { bubbles: true })
          this.element.dispatchEvent(newEvent)
        }.bind(this),
        false,
      );
      this.element.addEventListener(
        'removeItem',
        function() {
          const newEvent = new Event('change', { bubbles: true })
          this.element.dispatchEvent(newEvent)
        }.bind(this),
        false,
      );
    }
  }

  async performRemoteSearch(query) {
    var config = this.remoteSearchValue;
    config.params.query = query;
    
    const response = await fetch(config.url, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(config.params)
    });

    return response.json();
  }
}
import { Controller } from "stimulus"

export default class extends Controller {
  static values = {
    profileSlug: String
  };
  static targets = [ "newDropzone", "assetsList", "dropzoneActions" ]

  initialize() {
    window.imageIds = [];
    window.videoIds = [];
    window.folderIds = [];
    if(this.hasNewDropzoneTarget) {
      window.Assets.newUiInitializeDropzone(this.profileSlugValue, this.newDropzoneTarget.id);
      
      if (this.hasDropzoneActionsTarget) {
        this.fitDropzoneHeight();
      }
    }
  }


  fitDropzoneHeight() {
    const dropzoneOffset = this.newDropzoneTarget.getBoundingClientRect();
    const assetsListHeight = this.newDropzoneTarget.offsetHeight;
    const dropzoneGap = 10;
    const mainPageContentGap = 25;
    const minHeight = (window.innerHeight - dropzoneOffset.top );
    
    if(this.newDropzoneTarget.dataset.noAssets === 'true' ){
      this.newDropzoneTarget.style.top = `${assetsListHeight + dropzoneGap}px`
      this.newDropzoneTarget.style.height = `${minHeight - assetsListHeight - (dropzoneGap * 2) - mainPageContentGap}px`
    }
  }

  dragStart(e) {
    e.stopPropagation();
    e.preventDefault();
    if (e.dataTransfer.types && e.dataTransfer.types.includes('Files')){
      this.newDropzoneTarget.style.visibility = 'visible'
      this.dropzoneActionsTarget.style.visibility = 'visible'
      this.dropzoneActionsTarget.style.opacity = 1
    }
  }

  dragEnd(e) {
    e.stopPropagation();
    e.preventDefault();
    if (this.hasAssetsListTarget && !document.querySelector('.dz-details')) {
      this.newDropzoneTarget.style.visibility = 'hidden'
      this.dropzoneActionsTarget.style.visibility = 'visible'
      this.dropzoneActionsTarget.style.opacity = 0.7
    }
  }
}
// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction

// This example controller works with specially annotated HTML like:

// <div class="relative"
//      data-controller="dropdown"
//      data-action="click->dropdown#toggle click@window->dropdown#hide"
//      data-dropdown-active-target="#dropdown-button"
//      data-dropdown-active-class="bg-teal-600"
//      data-dropdown-invisible-class="opacity-0 scale-95"
//      data-dropdown-visible-class="opacity-100 scale-100"
//      data-dropdown-entering-class="ease-out duration-100"
//      data-dropdown-enter-timeout="100"
//      data-dropdown-leaving-class="ease-in duration-75"
//      data-dropdown-leave-timeout="75">
//  <div data-action="click->dropdown#toggle click@window->dropdown#hide" role="button" data-dropdown-target="button" tabindex="0" class="inline-block select-none">
//    Open Dropdown
//  </div>
//  <div data-dropdown-target="menu" class="absolute pin-r mt-2 transform transition hidden opacity-0 scale-95">
//    <div class="bg-white shadow rounded border overflow-hidden">
//      Content
//    </div>
//  </div>
// </div>

import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['menu', 'button']
  static values = { open: Boolean }

  connect() {
    this.visibleClass = this.data.get('visibleClass') || null
    this.invisibleClass = this.data.get('invisibleClass') || null
    this.activeClass = this.data.get('activeClass') || null
    this.enteringClass = this.data.get('enteringClass') || null
    this.leavingClass = this.data.get('leavingClass') || null

    if (this.hasButtonTarget) {
      this.buttonTarget.addEventListener("keydown", this._onMenuButtonKeydown)
      this.buttonTarget.setAttribute("aria-haspopup", "true")
    }
  }

  disconnect() {
    if (this.hasButtonTarget) {
      this.buttonTarget.removeEventListener("keydown", this._onMenuButtonKeydown)
      this.buttonTarget.removeAttribute("aria-haspopup")
    }
  }


  toggle() {
    this._calculateMenuPosition();
    this.openValue = !this.openValue
  }

  openValueChanged() {
    if (this.openValue) {
      this._show()
    } else {
      this._hide()
    }
  }

  /**
   * Calculates and sets the position of the dropdown menu.
   * This function ensures the menu is positioned correctly relative to its button, adjusting for viewport boundaries to prevent overflow.
  **/
  _calculateMenuPosition() {
    const viewportWidth = document.documentElement.clientWidth || window.innerWidth;
    const buttonRect = this.buttonTarget.getBoundingClientRect();

    // Store original menu style and temporarily make it visible but hidden
    const originalMenuStyle = this.menuTarget.style.cssText;
    this.menuTarget.style.cssText += ` 
      display: block !important;
      position: absolute !important;
      visibility: hidden !important;
      pointer-events: none !important;  
    `
    const menuRect = this.menuTarget.getBoundingClientRect();

    // Restore original menu style
    this.menuTarget.style.cssText = originalMenuStyle;

    const buttonPosition = {
      x: buttonRect.left + window.scrollX,
      y: buttonRect.top + window.scrollY,
      width: buttonRect.width,
      height: buttonRect.height
    }

    const menuPosition = {
      x: menuRect.left + window.scrollX,
      y: menuRect.top + window.scrollY,
      width: menuRect.width,
      height: menuRect.height
    }

    // Calculate the right edge of the menu if aligned with the button's left edge
    const menuRightEdge = buttonPosition.x + menuPosition.width;
    // Calculate the left edge of the menu if aligned with the buttons's right edge
    const menuLeftEdge = (buttonPosition.x +  buttonPosition.width) - menuPosition.width;

    // Check if menu would overflow any side of the screen. If it does, we calculate a new correct position for it to avoid this overflow
    if(menuRightEdge > viewportWidth || menuLeftEdge < 15 || this.element.classList.contains('static')){
      //If it overflows on both sides, we make it span the entire screen (accounting for margins)
      if(menuLeftEdge < 15 && menuRightEdge > viewportWidth){
        this.menuTarget.style.right = 15+'px';
        this.menuTarget.style.left = 15+'px';
        this.menuTarget.style.width = 'auto';
        this.element.classList.remove('relative');
        this.element.classList.add('static');
      }
      //Regular left overflow
      else if(menuLeftEdge < 15 && !this.menuTarget.classList.contains('start-0')){
        this.menuTarget.style.right = 'auto';
        this.menuTarget.style.left = 15+'px';
        this.element.classList.remove('relative');
        this.element.classList.add('static');
      }
      //Regular right overflow
      else if(menuRightEdge > viewportWidth && !this.menuTarget.classList.contains('end-0')){
        this.menuTarget.style.right = `${viewportWidth - (buttonPosition.x + buttonPosition.width)}px`;
        this.menuTarget.style.left = 'auto';
        this.element.classList.remove('relative');
        this.element.classList.add('static');
      }
      else if(this.element.classList.contains('static')){
        if(this.menuTarget.classList.contains('end-0')){
          this.menuTarget.style.right = `${(viewportWidth - 15) - (buttonPosition.x + buttonPosition.width)}px`;
          this.menuTarget.style.left = 'auto';
        }
        else if(this.menuTarget.classList.contains('start-0')){
          this.menuTarget.style.right = `${buttonPosition.x}px`;
          this.menuTarget.style.left = 'auto';
        }
      }
    }
    else{
      //Calculate if the menu width is bigger than the button width (for folders breadcrumb dropdown, the button could be bigger than the menu)
      const isMenuMainElement = menuPosition.width > buttonPosition.width ? true : false;

      if(!isMenuMainElement){
        this.menuTarget.style.left = `${(buttonPosition.x + buttonPosition.width) - menuPosition.width}px`;
        this.menuTarget.style.right = 'auto';
        this.element.classList.remove('relative');
        this.element.classList.add('static');
      }
    }
  }

  _show(cb) {
    this.menuTarget.style.display = 'block';

    setTimeout(
      (() => {
        if (this.hasButtonTarget) {
          this.buttonTarget.setAttribute("aria-expanded", "true")
        }
        this._enteringClassList[0].forEach(
          (klass => {
            this.menuTarget.classList.add(klass)
          }).bind(this),
        )

        this._activeClassList[0].forEach(klass => {
          this.activeTarget.classList.add(klass)
        })
        this._invisibleClassList[0].forEach(klass => this.menuTarget.classList.remove(klass))
        this._visibleClassList[0].forEach(klass => {
          this.menuTarget.classList.add(klass)
        })
        setTimeout(
          (() => {
            this._enteringClassList[0].forEach(klass => this.menuTarget.classList.remove(klass))
          }).bind(this),
          this.enterTimeout[0],
        )

        if (typeof cb == 'function') cb()
      }).bind(this),
    )
  }

  _hide(cb) {
    setTimeout(
      (() => {
        if (this.hasButtonTarget) {
          this.buttonTarget.setAttribute("aria-expanded", "false")
        }
        this._invisibleClassList[0].forEach(klass => {
          this.menuTarget.classList.add(klass)
        })
        this._visibleClassList[0].forEach(klass => this.menuTarget.classList.remove(klass))
        this._activeClassList[0].forEach(klass => this.activeTarget.classList.remove(klass))
        this._leavingClassList[0].forEach(klass => this.menuTarget.classList.add(klass))
        setTimeout(
          (() => {
            this._leavingClassList[0].forEach(klass => this.menuTarget.classList.remove(klass))
            this.menuTarget.style.display = 'none';

            if (typeof cb == 'function') cb()
          }).bind(this),
          this.leaveTimeout[0],
        )
      }).bind(this),
    )
  }

  _onMenuButtonKeydown = event => {
    switch (event.keyCode) {
      case 13: // enter
      case 32: // space
        event.preventDefault()
        this.toggle()
    }
  }

  show(){
     this.openValue = true;
  }

  forceHide() {
    this.openValue = false;
  }

  hide(event) {
    if (this.element.contains(event.target) === false && this.openValue) {
      this.openValue = false
    }
  }

  get activeTarget() {
    return this.data.has('activeTarget')
      ? document.querySelector(this.data.get('activeTarget'))
      : this.element
  }

  get _activeClassList() {
    return !this.activeClass
      ? [[], []]
      : this.activeClass.split(',').map(classList => classList.split(' '))
  }

  get _visibleClassList() {
    return !this.visibleClass
      ? [[], []]
      : this.visibleClass.split(',').map(classList => classList.split(' '))
  }

  get _invisibleClassList() {
    return !this.invisibleClass
      ? [[], []]
      : this.invisibleClass.split(',').map(classList => classList.split(' '))
  }

  get _enteringClassList() {
    return !this.enteringClass
      ? [[], []]
      : this.enteringClass.split(',').map(classList => classList.split(' '))
  }

  get _leavingClassList() {
    return !this.leavingClass
      ? [[], []]
      : this.leavingClass.split(',').map(classList => classList.split(' '))
  }

  get enterTimeout() {
    let timeout = this.data.get('enterTimeout') || '0,0'
    return timeout.split(',').map(t => parseInt(t))
  }

  get leaveTimeout() {
    let timeout = this.data.get('leaveTimeout') || '0,0'
    return timeout.split(',').map(t => parseInt(t))
  }
}

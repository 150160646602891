import { Controller } from "stimulus"

export default class extends Controller {

  static values = {
    brightnessUrl: String,
    ontimerUrl: String,
    offtimerUrl: String,
    rebootUrl: String,
    screenshotUrl: String,
    appUpdateUrl: String
  }

  static targets = [  "checkbox",
                      "bulkActionsDropdown",
                      "actionButton"
                    ]

  initialize() {
    window.screenIds = [];
    if(this.hasBulkActionsDropdownTarget){
      this.updateActionsUrl();
      this.toggleButton();
    }
  }

  selectAll() {
    if (event.target.checked) {
      this.checkboxTargets.forEach(target => {
        if (!target.checked && !target.disabled) {
          target.checked = true;
          window.screenIds.push(parseInt(target.value))
        }
      });
    } else {
      this.checkboxTargets.forEach(target => {
        if (target.checked) {
          target.checked = false;
        }
      });
      window.screenIds = [];
    }

    if(this.hasBulkActionsDropdownTarget){
      this.updateActionsUrl();
      this.toggleButton();
    }
  }

  checkbox() {
    const id = parseInt(event.target.value);

    if (event.target.checked) {
      window.screenIds.push(id);
    } else {
      var index = window.screenIds.indexOf(id);
      if (index !== -1) {
        window.screenIds.splice(index, 1);
      }
    }

    if(this.hasBulkActionsDropdownTarget){
      this.updateActionsUrl();
      this.toggleButton();
    }
  }

  toggleButton() {
    if (window.screenIds.length === 0) {
      this.bulkActionsDropdownTarget.classList.add('disabled', 'cursor-not-allowed', 'opacity-50');
      this.bulkActionsDropdownTarget.style.pointerEvents = 'none';
    } else {
      this.bulkActionsDropdownTarget.classList.remove('disabled', 'cursor-not-allowed', 'opacity-50');
      this.bulkActionsDropdownTarget.style.pointerEvents = 'all';
    }
  }

  updateActionsUrl() {
    if (window.screenIds.length === 0) {
      this.actionButtonTargets.forEach(target => {
        target.href = '#'
        target.style.opacity = 0.5;
        target.classList.add('cursor-not-allowed');
        target.style.pointerEvents = 'none';
      });
    } else {
      this.actionButtonTargets.forEach(target => {
        let actionUrl = '';
        switch(target.getAttribute('data-action-type')){
          case 'brightness':
            actionUrl = this.brightnessUrlValue + '?screen_ids=' + window.screenIds;
            break
          case 'ontimer':
            actionUrl = this.ontimerUrlValue + '?screen_ids=' + window.screenIds;
            break
          case 'offtimer':
            actionUrl = this.offtimerUrlValue + '?screen_ids=' + window.screenIds;
            break
          case 'reboot':
            actionUrl = this.rebootUrlValue + '?screen_ids=' + window.screenIds;
            break
          case 'screenshot':
            actionUrl = this.screenshotUrlValue + '?screen_ids=' + window.screenIds;
            break
          case 'appupdate':
            actionUrl = this.appUpdateUrlValue + '?screen_ids=' + window.screenIds;
            break
          default:
            actionUrl = '#';
        }
        target.href = actionUrl;
        target.style.opacity = 1;
        target.classList.remove('cursor-not-allowed');
        target.style.pointerEvents = 'all';
      });
    }
  }
}

import { Controller } from "stimulus"

export default class extends Controller {
  static values = {
    moveUrl: String,
    deleteUrl: String,
    activateUrl: String,
    folderId: Number,
    modelName: String
  };
  static targets = [ "checkbox", "moveButton", "actionsContainer", "deleteButton", "bulkActivationButton" ];

  initialize() {
    window.itemIds = [];
    window.videoIds = [];
    window.imageIds = [];
    window.folderIds = [];

    if (this.hasMoveButtonTarget) {
      this.populateIds();  
    } 
  }

  selectAllFolders(event) {
    this.selectItems(event, 'folder');
  }

  selectAllItems(event) {
    const itemType = event.currentTarget.dataset.itemType;
    this.selectItems(event, itemType);
  }

  selectItems(event, itemType) {
    if (event.target.checked) {
      this.checkboxTargets.forEach(target => {
        if (!target.checked) {
          if (target.dataset.itemType === itemType || (itemType === 'asset' && ['video', 'image'].includes(target.dataset.itemType))) {
            target.checked = true;
            switch (target.dataset.itemType) {
              case 'folder': 
                window.folderIds.push(parseInt(target.value));
                break;
              case 'image': 
                window.imageIds.push(parseInt(target.value));
                break;
              case 'video': 
                window.videoIds.push(parseInt(target.value));
                break;
              default: 
                window.itemIds.push(parseInt(target.value));
                break;
            }
          }
        }
      });
    } else {
      this.checkboxTargets.forEach(target => {
        if (target.checked && target.dataset.itemType === itemType || (itemType === 'asset' && ['video', 'image'].includes(target.dataset.itemType))) {
          target.checked = false;
        }
      });
      if (itemType === 'folder') {
        window.folderIds = [];
      } else if (itemType === 'asset') {
        window.imageIds = [];
        window.videoIds = [];
      } else {
        window.itemIds = [];
      }
    }
    this.updateIdsUrl();
    this.toggleButtons();
  }
  checkbox(event) {
    const id = parseInt(event.target.value);

    if (event.target.checked) {
      switch (event.target.dataset.itemType) {
        case 'folder':
          window.folderIds.push(id);
          break;
        case 'video':
          window.videoIds.push(id);
          break;
        case 'image':
          window.imageIds.push(id);
          break;
        default:
          window.itemIds.push(id);
          break;
      }
    } else {
      switch (event.target.dataset.itemType) {
        case 'folder':
          var index = window.folderIds.indexOf(id);
          if (index !== -1) {
            window.folderIds.splice(index, 1);
          }
          break;
        case 'image':
          var index = window.imageIds.indexOf(id);
          if (index !== -1) {
            window.imageIds.splice(index, 1);
          }
          break;
        case 'video':
          var index = window.videoIds.indexOf(id);
          if (index !== -1) {
            window.videoIds.splice(index, 1);
          }
          break;
        default:
          var index = window.itemIds.indexOf(id);
          if (index !== -1) {
            window.itemIds.splice(index, 1);
          }
          break;
      }
    }
    this.updateIdsUrl();
    this.toggleButtons();
  }

  populateIds() {
    this.checkboxTargets.forEach(target => {
      if (target.checked) {
        if (target.dataset.itemType == 'folder') {
          window.itemIds.push(parseInt(target.value))
        } else {
          window.folderIds.push(parseInt(target.value))
        }
      }
    });
    this.updateIdsUrl();
    this.toggleButtons();
  }

  updateIdsUrl() {
    if(this.hasMoveButtonTarget) {
      let moveUrl = `${this.moveUrlValue}?item_ids=${window.itemIds}&video_ids=${window.videoIds}&image_ids=${window.imageIds}&folder_ids=${window.folderIds}&type=${this.modelNameValue}`;
      if(this.folderIdValue) {
        moveUrl += '&folder_id=' + this.folderIdValue  
      }
      this.moveButtonTarget.href = moveUrl;
    }

    if(this.hasDeleteButtonTarget) {
      let deleteUrl = `${this.deleteUrlValue}?item_ids=${window.itemIds}&video_ids=${window.videoIds}&image_ids=${window.imageIds}&folder_ids=${window.folderIds}&type=${this.modelNameValue}`;
      if(this.folderIdValue) {
        deleteUrl += '&folder_id=' + this.folderIdValue
      }
      this.deleteButtonTarget.href = deleteUrl;
    }
    if(this.hasBulkActivationButtonTarget) {
      let activateUrl = `${this.activateUrlValue}?item_ids=${window.itemIds}&type=${this.modelNameValue}`;
      if(this.folderIdValue) {
        activateUrl += '&folder_id=' + this.folderIdValue
      }
      this.bulkActivationButtonTarget.href = activateUrl;
    }
    
  }

  toggleButtons() {
    const hasSelectedItems = [
      'itemIds',
      'folderIds',
      'videoIds',
      'imageIds'
    ].some(idType => window[idType]?.length > 0);
    this.actionsContainerTarget.style.display = hasSelectedItems ? 'flex': 'none';
  }
}